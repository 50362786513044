<!-- src/components/advanced/blog/blog-grid-item/BlogGridItem.vue -->
<script setup lang="ts">
import { type BlogPost } from '/@src/data/pages/blog'

const props = defineProps<{
  post: BlogPost;
  upsideDown?: boolean;
  link: string;
}>()
</script>

<template>
  <RouterLink
    :to="props.link"
    class="blog-grid-item"
  >
    <div class="card">
      <div class="card-image">
        <img
          class="block"
          :src="props.post.image"
          :alt="props.post.title"
          width="360"
          height="200"
        >
      </div>
      <div class="card-content">
        <p class="title is-5">
          {{ props.post.title }}
        </p>
        <p class="subtitle is-6">
          {{ props.post.category }}
        </p>
        <div class="content">
          <p>{{ props.post.abstract }}</p>
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
.blog-grid-item {
  display: block;
  height: 100%;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02); // Increase size by 2% on hover
  }

  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Add a subtle shadow on hover
    }

    .card-image {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .content {
        flex-grow: 1;
      }
    }
  }
}
</style>