import {
  START_LOCATION,
  createMemoryHistory,
  createRouter as createClientRouter,
  createWebHistory,
} from 'vue-router'
import routes from 'pages-generated'

export function createRouter() {
  const router = createClientRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior: async (to, from, savedPosition) => {
      console.log('Route change:', { from: from.fullPath, to: to.fullPath });

      // If navigating to /producten with a hash, let the component handle scrolling
      if (to.path === '/producten' && to.hash) {
        return false
      }

      // scroll instantly to hash on first view
      if (to.hash && from === START_LOCATION) {
        return {
          el: to.hash,
        }
      }

      // smooth scroll to hash on page change
      if (to.hash) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              el: to.hash,
              behavior: 'smooth',
            })
          }, 500)
        })
      }

      // return on saved position if any
      if (savedPosition) return savedPosition

      // scroll to top on page change
      console.log('Scrolling to top') // Debugging line
      return { top: 0, behavior: 'smooth' }
    },
  })

  // Add a global navigation guard
  router.beforeEach((to, from, next) => {
    console.log('Navigation guard:', { from: from.fullPath, to: to.fullPath });
    next();
  });

  // Add afterEach hook to track page views
  router.afterEach((to, from) => {
    console.log('Route changed:', { from: from.fullPath, to: to.fullPath });
    if (typeof fbq === 'function') {
      fbq('track', 'PageView');
    }
  });

  return router
}