import axios from 'axios'

const API_URL = import.meta.env.VITE_API_URL || '/api'

// Function to get CSRF token from cookies
function getCsrfToken() {
  const name = 'csrftoken='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim()
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return ''
}

const apiService = {
  async submitContactForm(formData: any) {
    try {
      const response = await axios.post(`${API_URL}/contact/`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
        },
      })
      return response.data
    } catch (error) {
      console.error('Error submitting contact form:', error)
      throw error
    }
  },

  async subscribeNewsletter(email: string) {
    try {
      const response = await axios.post(`${API_URL}/newsletter/`, { email }, {
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      })
      return response.data
    } catch (error) {
      console.error('Error subscribing to newsletter:', error)
      throw error
    }
  }
}

export default apiService