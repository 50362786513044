// frontend/src/data/pages/blog/index.ts

export interface BlogPost {
  id: number;
  slug: string;
  image: string;
  title: string;
  category: string;
  abstract: string;
  date: string;
  duration: number;
  views: string;
}

export const posts: BlogPost[] = [
  {
    id: 1,
    slug: 'waarom-verwarmen-met-een-airco-een-goed-idee-is',
    image: '/assets/images/blog/blog-thumbnail-1.svg',
    title: 'Waarom verwarmen met een airco een goed idee is!',
    category: 'TIPS',
    abstract: 'Zelfs zonder, maar zeker met zonnepanelen is het steeds interessanter om uw huis te verwarmen met een airco. In dit artikel leggen we uit waarom.',
    date: '22 April 2024',
    duration: 6,
    views: '1.1k',
  }
  // {
  //   id: 2,
  //   slug: 'hoeveel-kilowattuur-nodig-voor-air-conditioner',
  //   image: '/assets/images/blog/blog-thumbnail-2.svg',
  //   title: 'Hoeveel Kilowattuur heb je nodig voor een air-conditioner?',
  //   category: 'product-keuze',
  //   abstract: 'Een vraag die wij vaak gesteld krijgen is hoeveel kilowattuur je nodig hebt voor een warmtepomp. In dit artikel leggen we uit hoe je de juiste keuze maakt.',
  //   date: 'March 17th, 2022',
  //   duration: 6,
  //   views: '1.1k',
  // },
  // {
  //   id: 3,
  //   slug: 'uw-woning-verduurzamen-met-clima-plus',
  //   image: '/assets/images/blog/blog-thumbnail-3.svg',
  //   title: 'Uw woning verduurzamen met Clima Plus!',
  //   category: 'Verduurzaming',
  //   abstract: 'Met Clima Plus kan je je woning verduurzamen en besparen op je energiefactuur. Ontdek hier hoe het werkt!',
  //   date: 'March 17th, 2022',
  //   duration: 6,
  //   views: '1.2k',
  // }
]