<!-- src/components/advanced/blog/blog-grid/BlogGrid.vue -->
<script setup lang="ts">
import { computed } from 'vue'
import { posts, type BlogPost } from '/@src/data/pages/blog'

export interface BlogGridProps {
  upsideDown?: boolean;
  limit?: number;
  sideGrid?: boolean;
  currentPostId?: number;
}

const props = withDefaults(defineProps<BlogGridProps>(), {
  upsideDown: false,
  limit: 3,
  sideGrid: false,
  currentPostId: undefined,
})

const filteredPosts = computed(() => {
  return posts
    .filter(post => post.id !== props.currentPostId)
    .slice(0, props.limit)
})

const columnClasses = computed(() => {
  return props.sideGrid ? 'is-12' : 'is-4'
})
</script>

<template>
  <div class="blog-grid">
    <div class="columns is-multiline">
      <div
        v-for="post in filteredPosts"
        :key="post.id"
        class="column is-4-desktop is-6-tablet"
      >
        <BlogGridItem
          :post="post"
          :upside-down="props.upsideDown"
          :link="`/blog/${post.slug}`"
        />
      </div>
    </div>
  </div>
</template>