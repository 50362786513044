<script setup lang="ts">
export interface ResponsiveImageProps {
  src: string
  alt?: string
  width?: string | number
  height?: string | number
  inline?: boolean
}

const props = withDefaults(defineProps<ResponsiveImageProps>(), {
  alt: '',
  width: undefined,
  height: undefined,
  inline: false,
})

const classes = computed(() => [
  props.inline ? 'is-image-inline' : 'is-image-block',
  'is-image',
])

const imageStyle = computed(() => {
  if (props.width && props.height) {
    const aspectRatio = Number(props.width) / Number(props.height)
    return {
      aspectRatio: `${aspectRatio}`,
      maxHeight: '80vh', // Limit the maximum height to 80% of the viewport height
    }
  }
  return {}
})
</script>

<template>
  <component :is="props.inline ? 'span' : 'div'" :class="classes">
    <img
      :src="props.src"
      :alt="props.alt"
      :width="props.width"
      :height="props.height"
      loading="lazy"
      class="is-image"
      :style="imageStyle"
    >
  </component>
</template>

<style scoped>
.is-image-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.is-image-inline {
  display: inline-block;
}
</style>