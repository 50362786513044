import type { VulkConfig } from '/@src/types'

/**
 * Set your default Navbar by importing a vue component
 * Note: If you rename the import, make sure to update the type definition bellow
 *
 * @example import Navbar from '/@src/components/blocks/navbar-blocks/NavbarA.vue'
 */
import Navbar from '/@src/components/navigation/navbar/Navbar.vue'

/**
 * Set your default Footer by importing a vue component
 * Note: If you rename the import, make sure to update the type definition bellow
 *
 * @example import Footer from '/@src/components/blocks/footer-blocks/FooterA.vue'
 */
import Footer from '/@src/components/layout/footer/Footer.vue'

// Auto detect the type of the imported components
type NavbarType = typeof Navbar
type FooterType = typeof Footer
type AppConfig = VulkConfig<NavbarType, FooterType>

/**
 * This is the main configuration file for the app
 */
export default {
  name: 'Clima Plus',
  title: 'Clima Plus',
  description:
    'Clima Plus biedt een ongeëvenaarde service in airconditioning en onderhoud. Wij zijn gespecialiseerd in het installeren en onderhouden van airconditioning en warmtepompen.',
  logo: {
    src: '/assets/logo/logo.svg',
    width: 90,
    height: 90,
  },
  layout: {
    // Default page transition
    // @see /src/scss/utilities/_transitions.scss
    transition: 'fade-fast',
    backToTop: {
      // Display the back to top button
      enabled: true,
    },
    navbar: {
      enabled: true,
      component: Navbar,
      props: {
        // define default props for Navbar here, you can override them using page meta
        items: [
          {
            label: 'Home',
            to: '/',
          },
          {
            label: 'Producten',
            to: '/producten',
          },
          {
            label: 'Onderhoud',
            to: '/onderhoud',
          },
          {
            label: 'Blog',
            to: '/blog',
          },
          {
            label: 'FAQ',
            to: '/#faq',
          },
          {
            label: 'Contact',
            to: '/#contact-formulier',
          }
        ],
      },
    },
    footer: {
      enabled: true,
      component: Footer,
      props: {
        content: {
          columns: [ // Assuming each column needs a title and an array of links
            {
              title: "Products",
              links: [
                { label: 'Producten', to: '/producten' },
                { label: 'Onderhoud', to: '/onderhoud' }
              ]
            },
            {
              title: "Services",
              links: [
                { label: 'FAQ', to: '/faq' },
                { label: 'Contact', to: '/#contact-formulier' }
              ]
            }
          ],
          socials: [
            { icon: 'ph:facebook-logo-fill', to: 'https://www.facebook.com', name: 'Facebook' },
            { icon: 'ph:instagram-logo-fill', link: 'https://www.instagram.com', name: 'Twitter' },
            { icon: 'ph:linkedin-logo-fill', link: 'https://www.linkedin.com', name: 'LinkedIn' }
          ],
          links: [
            { label: 'Algemene Voorwaarden', to: '/terms' }
          ],
          cta: {
            title: "Stay Updated",
            subtitle: "Subscribe to our newsletter",
            benefits: [
              { label: "Get the latest news" }
            ],
            links: [
              { label: "Learn more", to: "/learn-more" }
            ]
          },
          copyright: '© 2024 Clima Plus'
        }
      }
    },
  },
  url: 'http://clima-plus.nl',  // Add a default or relevant URL

} as AppConfig
