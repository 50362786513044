export const genericFaq = [
  {
    title: 'Kun je ook verwarmen met Clima Plus airco\'s?',
    content: 'Jazeker, de Clima Plus airco\'s zijn inverters, waardoor ze niet alleen kunnen koelen tijdens de zomermaanden maar ook kunnen verwarmen in de winter. Dit maakt ze een veelzijdige keuze voor het hele jaar door. Vooral gezien de alsmaar stijgende gasprijzen.' 
  },
  {
    title: 'Moet ik een aparte wifi-module installeren om de airco via de app te bedienen?',
    content: 'Alle modellen van Clima Plus zijn  voorzien van ingebouwde wifi-functionaliteit, waardoor geen aparte module nodig is. In tegen stelling tot bepaalde concurrenten waarvan hun airco\'s enkel "wifi-ready" zijn, heb je bij Clima Plus dus niks anders nodig dan een smartphone om uw Clima Plus airco met de app via het internet te bedienen.'
  },
  {
    title: 'Wanneer heb ik een 3.5kW airco nodig en wanneer een 5kW airco?',
    content: 'Neem een kijkje naar onze productpagina voor informatie die u kan helpen besluiten welke airco unit goed voor u is!'
  },
  {
    title: 'Kan ik meerdere binnen-units aansluiten op één buiten-unit?',
    content: 'Dat is zeker mogelijk. Bij het Clima+ Duo model worden er twee binnen-units aangesloten aan één buiten-unit. Hiermeek unt u afzonderlijk twee verschillende ruimters verkoelen of verwarmen. Let er wel op dat het niet mogelijk is één ruimte te verkoelen terwijl u een andere ruimte verwarmd.'
  },
  {
    title: 'Kan ik ook een airco installeren zonder buiten-unit?',
    content: 'Ja, er zijn modellen beschikbaar die geen buiten-unit vereisen. Het is belangrijk om te weten dat deze modellen minder efficiënt zijn dan split-systemen en om deze reden hebben wij ervoor gekozen deze technologie niet op te nemen in ons aanbod. We weten dat een airco-installatie een investering is, daarom willen wij producten op de markt brengen die de beste verhouding tussen prijs en prestatie bieden.'
  },
  {
    title: 'Hoe luid is de binnen-unit van een airco? En de buitenunit?',
    content: 'De binnen-units van onze airconditioners zijn over het algemeen zeer stil, met geluidsniveaus rond de 25 dB voor standaardmodellen. De buiten-units produceren iets meer geluid, gemiddeld rond de 35 dB, wat vergelijkbaar is met een rustige straat. Voor de precieze waardes per unit-type verwijzen we u graag naar onze productpagina.'
  }
]
