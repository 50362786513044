<script setup lang="ts"></script>

<template>
  <div class="bubbles">
    <div
      class="bubble"
      style="
        /* stylelint-disable */
        --size: 3.3909991037595413rem;
        --distance: 8.564411425917513rem;
        --position: -3.3532309343188405%;
        --time: 3.337641675397866s;
        --delay: -3.8598811310658885s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.6696180078573795rem;
        --distance: 8.237386989233332rem;
        --position: 53.83419782426631%;
        --time: 3.9822421087458917s;
        --delay: -3.89753449421937s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.583421366775942rem;
        --distance: 9.38128061429176rem;
        --position: 54.60126619661698%;
        --time: 2.517052007029066s;
        --delay: -3.78693802909404s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.134675080779443rem;
        --distance: 9.64239168932307rem;
        --position: 96.27307149172402%;
        --time: 3.4754673820204447s;
        --delay: -3.5529064848993803s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.257650665640758rem;
        --distance: 7.436513802589119rem;
        --position: 58.07638903927696%;
        --time: 3.5787606993702172s;
        --delay: -3.727907387715606s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.229508255244221rem;
        --distance: 9.9519353103117rem;
        --position: 74.21436924845615%;
        --time: 3.028254580955734s;
        --delay: -3.2558799292110705s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.675200319744859rem;
        --distance: 6.150496605028215rem;
        --position: 16.40803187630667%;
        --time: 2.095930094316172s;
        --delay: -2.012984983796527s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.3761417212986435rem;
        --distance: 6.8733372826509695rem;
        --position: 67.33293542719909%;
        --time: 3.488941974619582s;
        --delay: -2.334601722943579s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.950301563617759rem;
        --distance: 8.773235974561047rem;
        --position: 72.33514453946891%;
        --time: 2.52996034300591s;
        --delay: -3.093155150723837s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.7962980861050566rem;
        --distance: 9.376934017605382rem;
        --position: 0.9284191465863394%;
        --time: 3.2726752420410317s;
        --delay: -3.2746503820920023s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.721084395955664rem;
        --distance: 9.160889629565318rem;
        --position: 17.773927915690802%;
        --time: 3.288341342360385s;
        --delay: -2.714601022186284s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.235707949222566rem;
        --distance: 7.440804351858876rem;
        --position: 43.28529568082537%;
        --time: 2.1995706778064124s;
        --delay: -3.680528188133694s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.158862079420235rem;
        --distance: 9.533854451886327rem;
        --position: 71.026240990692%;
        --time: 2.8692037773746577s;
        --delay: -3.5557770672814346s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.839296642714123rem;
        --distance: 6.182489448536465rem;
        --position: 14.53940914818159%;
        --time: 2.938568419305686s;
        --delay: -3.81476405451273s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.128799911734943rem;
        --distance: 6.638351699090389rem;
        --position: 91.1729184083293%;
        --time: 3.7693474455517046s;
        --delay: -3.2623719303917116s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.831089849696752rem;
        --distance: 6.720856255476927rem;
        --position: -0.2918262612150073%;
        --time: 3.6110339045076714s;
        --delay: -3.6108734761894308s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.020256558189085rem;
        --distance: 6.267891684076174rem;
        --position: 96.60115612092137%;
        --time: 2.9356504950946087s;
        --delay: -3.5552549840595s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.7691993127248082rem;
        --distance: 8.913331548077153rem;
        --position: 64.18486372751045%;
        --time: 2.19108565704424s;
        --delay: -2.3850044581272285s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.5186018838504385rem;
        --distance: 9.00269648065624rem;
        --position: 58.6327746157483%;
        --time: 2.720628148521575s;
        --delay: -3.6440357385386735s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.0117547687868296rem;
        --distance: 9.115107261515343rem;
        --position: 100.9452218837347%;
        --time: 3.3363937247903426s;
        --delay: -2.5595759900265675s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.4862984438637445rem;
        --distance: 7.129506738714858rem;
        --position: 83.4563610800965%;
        --time: 3.7410400553323466s;
        --delay: -2.0998148963173597s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.1026083685029695rem;
        --distance: 7.881255983545695rem;
        --position: 82.32077922491695%;
        --time: 3.4140045352663657s;
        --delay: -2.8734049295595723s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.413917492099269rem;
        --distance: 9.742462890108262rem;
        --position: 16.42768284622023%;
        --time: 2.8317938388802966s;
        --delay: -3.82756118716143s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.247645887141396rem;
        --distance: 9.679735105547111rem;
        --position: 37.784264064331666%;
        --time: 2.3688024830461236s;
        --delay: -3.0842018769578705s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.789110359285048rem;
        --distance: 6.593960963371451rem;
        --position: 6.030790018049801%;
        --time: 3.0111113239562775s;
        --delay: -2.779069427841379s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.920078231724909rem;
        --distance: 8.019055319208483rem;
        --position: -0.6697456597529232%;
        --time: 2.7322573233597005s;
        --delay: -3.4562197990467887s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.9483144955502905rem;
        --distance: 7.328804117604981rem;
        --position: 86.85526610648378%;
        --time: 2.253772946450089s;
        --delay: -2.335258694249167s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.986742241050141rem;
        --distance: 9.372473590967486rem;
        --position: 11.645424862100391%;
        --time: 2.767927866801652s;
        --delay: -3.6036251608689076s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.685994744877967rem;
        --distance: 6.7313548620829895rem;
        --position: 31.27613675088076%;
        --time: 2.2925754221462955s;
        --delay: -3.737222941850242s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.1129848616561464rem;
        --distance: 7.973768390749606rem;
        --position: 6.987046974998384%;
        --time: 3.0081815354815857s;
        --delay: -2.0327254202297422s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.39464970547949rem;
        --distance: 8.07436160084725rem;
        --position: 64.73075164596573%;
        --time: 2.4579050831447478s;
        --delay: -3.483566318006404s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.3473946740234917rem;
        --distance: 9.404893160186822rem;
        --position: 77.80519247629697%;
        --time: 2.2118259189568836s;
        --delay: -2.950624976334687s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.068613811981525rem;
        --distance: 7.16414069186144rem;
        --position: 2.613045732629777%;
        --time: 3.8833174303115174s;
        --delay: -2.574991188736209s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.2798633470639915rem;
        --distance: 7.299445260082528rem;
        --position: 48.02708581947619%;
        --time: 3.7242961387855673s;
        --delay: -2.4734505003243488s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.521576158610709rem;
        --distance: 8.795523625782202rem;
        --position: 23.798667098738793%;
        --time: 2.0159378222175746s;
        --delay: -2.981385535625563s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.590771748441718rem;
        --distance: 8.671193839332254rem;
        --position: 59.88587536871286%;
        --time: 3.3701772130578806s;
        --delay: -3.022271750369279s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.5809954125751933rem;
        --distance: 9.753267128984376rem;
        --position: 50.85335330973102%;
        --time: 2.3057178413458725s;
        --delay: -3.020899022389121s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.0815595676298715rem;
        --distance: 7.588289558521317rem;
        --position: 85.59643940178883%;
        --time: 3.060048727833308s;
        --delay: -2.756730474390774s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.373035673491345rem;
        --distance: 9.193862476126496rem;
        --position: 27.97339829913929%;
        --time: 3.7036581304578684s;
        --delay: -3.2532584538584426s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.0177576651592606rem;
        --distance: 8.25581554501883rem;
        --position: 41.445550192268904%;
        --time: 3.3367688529272983s;
        --delay: -2.355956333561552s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.446809431022613rem;
        --distance: 6.754925020894915rem;
        --position: 78.40046396702171%;
        --time: 2.2449086779891188s;
        --delay: -3.4123304867959097s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.119761777495828rem;
        --distance: 9.673000451938382rem;
        --position: 48.731326458530404%;
        --time: 3.698361328373862s;
        --delay: -2.1274592329806534s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.2860388045291rem;
        --distance: 7.153678069730069rem;
        --position: 80.4781367947798%;
        --time: 3.1750612038562958s;
        --delay: -3.8852488110613286s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.735502858755306rem;
        --distance: 8.865350323099571rem;
        --position: 57.06005161307801%;
        --time: 3.783242459657551s;
        --delay: -3.029505445942032s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.210656076916611rem;
        --distance: 6.009244755368356rem;
        --position: 46.407251437646885%;
        --time: 2.825860489876731s;
        --delay: -3.2693626984936204s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.766361012511409rem;
        --distance: 6.85814516018899rem;
        --position: 67.06485868169764%;
        --time: 3.1537974261224564s;
        --delay: -3.450262969142444s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.3488025390021123rem;
        --distance: 9.612803488962832rem;
        --position: 62.36777292198522%;
        --time: 2.2375997498686555s;
        --delay: -3.7258814246212713s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.178478450680572rem;
        --distance: 9.33111165821956rem;
        --position: 55.573913174815964%;
        --time: 3.084099384367685s;
        --delay: -2.733423200114791s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.6664140911162946rem;
        --distance: 9.177588333375873rem;
        --position: 51.59113240785493%;
        --time: 2.8487881015329486s;
        --delay: -2.0884620845733206s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.159148517542034rem;
        --distance: 6.772121396496405rem;
        --position: 31.14032071428491%;
        --time: 2.6104965761579764s;
        --delay: -3.8166906692882345s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.052509258312416rem;
        --distance: 6.7251413046251285rem;
        --position: 93.36363860798906%;
        --time: 3.096209009165428s;
        --delay: -2.911499510169647s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.6238521272488065rem;
        --distance: 9.414699267673594rem;
        --position: -2.710533984752892%;
        --time: 2.510836794845091s;
        --delay: -2.5256442963257s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.44869346013966rem;
        --distance: 9.839004119281974rem;
        --position: 17.82354402258013%;
        --time: 2.5840723049998133s;
        --delay: -3.203744291425222s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.070686017747734rem;
        --distance: 7.5811867676163285rem;
        --position: 75.84540633053267%;
        --time: 2.66470686343186s;
        --delay: -3.7246871561459676s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.931055521818523rem;
        --distance: 7.781245985269705rem;
        --position: 81.287749871316%;
        --time: 3.7325004412259672s;
        --delay: -3.823751726679453s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.823727017741548rem;
        --distance: 8.680575435211933rem;
        --position: 73.41484797655549%;
        --time: 3.847822454935853s;
        --delay: -3.5713216202695834s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.61354729020547rem;
        --distance: 8.016062914507415rem;
        --position: 17.862231081687252%;
        --time: 2.0262747547949496s;
        --delay: -2.9054149087442966s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.171464580456588rem;
        --distance: 6.67750654155789rem;
        --position: 12.877373334279529%;
        --time: 3.5580480776198007s;
        --delay: -2.5643180337421696s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.281799874468128rem;
        --distance: 7.402283695209132rem;
        --position: 92.14425186343497%;
        --time: 2.5597489463759797s;
        --delay: -3.036048026201896s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.5385087119725105rem;
        --distance: 8.499461870354953rem;
        --position: 72.73028953359037%;
        --time: 2.7887039907628055s;
        --delay: -3.6488734803452436s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.891997229612797rem;
        --distance: 8.354407163186103rem;
        --position: 94.96690664913535%;
        --time: 2.618841406031295s;
        --delay: -3.3548464186255145s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.227363016327783rem;
        --distance: 7.800728284646278rem;
        --position: 56.294851513631144%;
        --time: 2.7133563197578066s;
        --delay: -3.096979206806668s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.739172103303974rem;
        --distance: 9.891849823611423rem;
        --position: 74.49895783921238%;
        --time: 3.6886107554620327s;
        --delay: -3.510191114742537s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.421085990059188rem;
        --distance: 6.256033768711507rem;
        --position: 93.53812658257817%;
        --time: 2.1992935518810945s;
        --delay: -2.175889437142541s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.344481511276051rem;
        --distance: 6.283199395503798rem;
        --position: 89.61565822194707%;
        --time: 3.5643031609864906s;
        --delay: -3.959588602508511s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.209007682480875rem;
        --distance: 8.101500580256277rem;
        --position: 69.24331329430895%;
        --time: 2.198564269984541s;
        --delay: -2.462579700285663s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.3383579577767rem;
        --distance: 6.653186947810153rem;
        --position: 67.03577186417215%;
        --time: 2.8786070487521997s;
        --delay: -2.20454402209523s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.823580477533595rem;
        --distance: 6.5098937871165665rem;
        --position: 3.420957810491931%;
        --time: 3.706848140064218s;
        --delay: -2.3567957646358324s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.157368405482526rem;
        --distance: 9.214370332609803rem;
        --position: -0.5867731537098386%;
        --time: 2.0904965824613924s;
        --delay: -2.7212640326222095s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.2043606210480835rem;
        --distance: 9.095707429391076rem;
        --position: 58.36107740732197%;
        --time: 2.9908845853046886s;
        --delay: -2.572158961653693s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.811790786991268rem;
        --distance: 7.981470136435225rem;
        --position: 81.06141798840261%;
        --time: 2.5468102938151005s;
        --delay: -2.3131948507922773s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.00272543120441rem;
        --distance: 7.980983422755734rem;
        --position: 74.61378155522974%;
        --time: 2.968234123029186s;
        --delay: -3.986349526346934s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.2596548746778335rem;
        --distance: 9.079907877204871rem;
        --position: 70.23395365354816%;
        --time: 3.976450942392394s;
        --delay: -3.509251494384498s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.582859438909359rem;
        --distance: 8.923980038241375rem;
        --position: 60.340401252906915%;
        --time: 2.4909304161952317s;
        --delay: -2.1260401260517794s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.813862151987901rem;
        --distance: 8.820535688629317rem;
        --position: 25.19285739679372%;
        --time: 2.5341214688674283s;
        --delay: -3.03638517864624s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.141183955609524rem;
        --distance: 6.720916879535818rem;
        --position: 72.0612753825845%;
        --time: 2.489546452597833s;
        --delay: -3.0899872742777683s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.3437047154270125rem;
        --distance: 8.766246383587918rem;
        --position: 91.42371418764229%;
        --time: 3.9644225096858716s;
        --delay: -3.520771352803094s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.8932316199853894rem;
        --distance: 6.888810028303249rem;
        --position: 73.07870579168002%;
        --time: 2.032490855810441s;
        --delay: -3.3605157475592806s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.304094450186494rem;
        --distance: 9.36508915937441rem;
        --position: 1.4231669458234002%;
        --time: 3.2795935664853166s;
        --delay: -2.5319816003476983s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.86192249389239rem;
        --distance: 6.448941068000797rem;
        --position: 88.46300038168083%;
        --time: 2.46349751508115s;
        --delay: -2.0418007032088217s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.8098804439082583rem;
        --distance: 7.076276013668111rem;
        --position: -4.063755548995696%;
        --time: 3.501601937750805s;
        --delay: -2.1273849487168497s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.989460559629274rem;
        --distance: 8.338124436850489rem;
        --position: 7.619389135416629%;
        --time: 2.405135583728881s;
        --delay: -2.677576124647879s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.1582945153915665rem;
        --distance: 9.216471733518325rem;
        --position: 43.85124448828942%;
        --time: 2.7764852662159707s;
        --delay: -2.7742496638072827s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.6285718405746623rem;
        --distance: 6.628521679553981rem;
        --position: 21.124736160484627%;
        --time: 3.4253874692959276s;
        --delay: -2.0953471920228606s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.9430876217156747rem;
        --distance: 6.781164144612265rem;
        --position: 74.47766854449249%;
        --time: 3.620805559774492s;
        --delay: -2.1240434684458402s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.074851194973692rem;
        --distance: 9.086548947209486rem;
        --position: 75.83852753172074%;
        --time: 2.387479605790185s;
        --delay: -3.665466810334584s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.749084022205681rem;
        --distance: 7.765306148237496rem;
        --position: 22.531385961635014%;
        --time: 2.3130633028880734s;
        --delay: -3.524327961605754s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.690610733390235rem;
        --distance: 8.394562143366585rem;
        --position: 42.477409986274495%;
        --time: 2.6697121820825958s;
        --delay: -2.785933136307151s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.654748252107443rem;
        --distance: 8.67948539229581rem;
        --position: 80.43751720454404%;
        --time: 2.7811754399419404s;
        --delay: -3.132506828585779s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.8631960371250234rem;
        --distance: 7.579742670765806rem;
        --position: 68.19385692887155%;
        --time: 3.193655006027997s;
        --delay: -3.638864847317068s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.605091376041317rem;
        --distance: 7.048907420952617rem;
        --position: 59.18067923578724%;
        --time: 2.515473639174605s;
        --delay: -3.329557366467138s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.1163164968443127rem;
        --distance: 9.01868177931328rem;
        --position: 87.90680094913662%;
        --time: 3.755352090531656s;
        --delay: -2.8573378525347373s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.2246547823649836rem;
        --distance: 6.194972593029024rem;
        --position: 104.53128481497785%;
        --time: 3.090360499420227s;
        --delay: -3.27889820325405s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.263249780917242rem;
        --distance: 9.496145456857949rem;
        --position: 70.7876981102033%;
        --time: 3.3184720975690243s;
        --delay: -3.49356768768204s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.9118376365635488rem;
        --distance: 7.54255836288946rem;
        --position: 45.2367299478124%;
        --time: 3.2103947450873043s;
        --delay: -3.3819280961216895s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.0468617327871037rem;
        --distance: 8.291362280518335rem;
        --position: 67.71272719984518%;
        --time: 3.670829595880588s;
        --delay: -3.126107493577883s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.330552044004739rem;
        --distance: 7.863098654077696rem;
        --position: 90.56988973163341%;
        --time: 2.1064313241535326s;
        --delay: -3.074838974833831s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.8369631675641953rem;
        --distance: 7.01015600048305rem;
        --position: 91.00536950491212%;
        --time: 2.6678779884103374s;
        --delay: -3.1858550796341363s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.23101807139781rem;
        --distance: 6.313029116155726rem;
        --position: 103.3429964126971%;
        --time: 3.8403360894710823s;
        --delay: -3.369417963627932s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.796656329735483rem;
        --distance: 9.442966739994851rem;
        --position: 7.375722768439207%;
        --time: 3.161660525404629s;
        --delay: -2.310223265375966s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.877035098998367rem;
        --distance: 6.74753994383097rem;
        --position: 96.8100022542338%;
        --time: 2.946731391231958s;
        --delay: -3.9160543930085043s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.2807456166024282rem;
        --distance: 8.14199932906823rem;
        --position: 43.06913448020839%;
        --time: 3.598343864495563s;
        --delay: -2.2332977634842854s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.1430522374943317rem;
        --distance: 6.618876339317328rem;
        --position: 86.62100767679523%;
        --time: 3.41215345741336s;
        --delay: -2.1622858560178866s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.841820891042348rem;
        --distance: 6.792663462265825rem;
        --position: 41.51432637876691%;
        --time: 3.8539796309214767s;
        --delay: -2.600818884719666s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.32621822881762rem;
        --distance: 6.618214802867413rem;
        --position: 74.20527249008273%;
        --time: 3.1652743852504166s;
        --delay: -3.96047773357275s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.544041747530711rem;
        --distance: 8.347885281730663rem;
        --position: 24.913439432132293%;
        --time: 2.0607889236213675s;
        --delay: -2.4343410949584623s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.321021498808678rem;
        --distance: 9.70554783839451rem;
        --position: 36.825405770937074%;
        --time: 2.8966715317338014s;
        --delay: -3.885502129199308s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.688077310556602rem;
        --distance: 7.745393133255601rem;
        --position: 70.20425675959358%;
        --time: 3.1507089975523788s;
        --delay: -3.211149498959459s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.316504248147513rem;
        --distance: 8.993087460169003rem;
        --position: 12.414185362013907%;
        --time: 2.0780979719662107s;
        --delay: -2.639003441232615s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.864500486860962rem;
        --distance: 9.50232826299078rem;
        --position: 3.2003016272630003%;
        --time: 2.6274464459833418s;
        --delay: -2.2113220514402125s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.95646932624161rem;
        --distance: 8.67113585624476rem;
        --position: 69.28822209606714%;
        --time: 2.571551330744293s;
        --delay: -3.055810904423768s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.695140546784895rem;
        --distance: 8.095107613250537rem;
        --position: 51.82219016825685%;
        --time: 2.8083995825653365s;
        --delay: -2.452790929131188s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.640888137839771rem;
        --distance: 6.911143818437908rem;
        --position: 85.14162673144158%;
        --time: 3.9754842303660527s;
        --delay: -3.422254666813271s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.5394262403718084rem;
        --distance: 7.2933117906736395rem;
        --position: 79.78231267033028%;
        --time: 2.507260577183893s;
        --delay: -2.8961215105159646s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.7338652511388792rem;
        --distance: 9.114307377417799rem;
        --position: -0.02100473026951377%;
        --time: 3.038417036346424s;
        --delay: -3.8099452399016456s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.660182858222358rem;
        --distance: 7.403411470018584rem;
        --position: 71.600601011282%;
        --time: 3.1919049295620208s;
        --delay: -2.2048998022013735s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.607146147070592rem;
        --distance: 7.580619129748361rem;
        --position: 45.36901774620973%;
        --time: 2.4888755808376812s;
        --delay: -2.5203782355697544s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.1658928139201468rem;
        --distance: 8.51914683224664rem;
        --position: 22.262310930326194%;
        --time: 2.215393187890515s;
        --delay: -2.371020485591005s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.116606920316468rem;
        --distance: 8.346247544711325rem;
        --position: 92.32130501494447%;
        --time: 3.51182116829452s;
        --delay: -3.7075069022699734s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.196468757207845rem;
        --distance: 9.956668905424177rem;
        --position: 11.313501119290084%;
        --time: 2.814963876915057s;
        --delay: -3.979646495299227s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 4.088322669209462rem;
        --distance: 6.656780902798046rem;
        --position: 24.79202684694924%;
        --time: 2.6960416696848584s;
        --delay: -3.5171291094095114s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.204512407638305rem;
        --distance: 6.000533085091521rem;
        --position: 77.30213039360169%;
        --time: 2.3169390187623913s;
        --delay: -2.7060217980401493s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 3.6534408159461718rem;
        --distance: 9.786448426491647rem;
        --position: 24.6511816935164%;
        --time: 3.3531497373792685s;
        --delay: -2.572031228711767s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.489044383242251rem;
        --distance: 8.4884451537671rem;
        --position: 40.635517074988485%;
        --time: 2.947788487864304s;
        --delay: -2.0887126814275954s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.993326938438914rem;
        --distance: 9.058271988018785rem;
        --position: -0.6219520160203658%;
        --time: 2.375447314713027s;
        --delay: -3.4671727803659484s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.310014357755408rem;
        --distance: 6.261080742026377rem;
        --position: 18.440785036079813%;
        --time: 3.712239675945384s;
        --delay: -2.8441539761803476s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 5.108799905086245rem;
        --distance: 7.3257389221303075rem;
        --position: 45.157500320831126%;
        --time: 3.3336194671776638s;
        --delay: -2.277962042814465s;
      "
    />
    <div
      class="bubble"
      style="
        --size: 2.8430173924088527rem;
        --distance: 7.7131580776253275rem;
        --position: 71.37462542880793%;
        --time: 2.088868085726502s;
        --delay: -2.7256361295573908s;
      "
    />
  </div>
</template>

<style scoped lang="scss">
.footer:not(.footer-dark):not(.footer-light) {
  .bubbles {
    .bubble {
      background: var(--footer-default-bg-color);
    }
  }
}

.footer-dark {
  .bubbles {
    .bubble {
      background: var(--footer-dark-bg-color);
    }
  }
}

.footer-light {
  .bubbles {
    .bubble {
      background: var(--footer-light-bg-color);
    }
  }
}

.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  filter: url('#blob');

  .bubble {
    position: absolute;
    left: var(--position, 50%);
    border-radius: 100%;
    animation:
      bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s),
      bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
    transform: translate(-50%, 100%);
    z-index: 10;
  }

  @keyframes bubble-size {
    0%,
    75% {
      width: var(--size, 4rem);
      height: var(--size, 4rem);
    }

    100% {
      width: 0;
      height: 0;
    }
  }

  @keyframes bubble-move {
    0% {
      bottom: -4rem;
    }

    100% {
      bottom: var(--distance, 10rem);
    }
  }
}
</style>
