<script setup lang="ts">
import config from '/@src/app.config'
import { useNinjaWindowScroll } from '/@src/composables/window-scroll'

export type NavbarMode = 'solid' | 'fade' | 'inverted'

export interface NavbarProps {
  fixed?: boolean
  dropdownBoxed?: boolean
  mode?: NavbarMode
}

const props = withDefaults(defineProps<NavbarProps>(), {
  fixed: false,
  dropdownBoxed: false,
  mode: 'fade',
})

const { y } = useNinjaWindowScroll()
const isScrolled = computed(() => {
  return y.value > 60
})
const isNavbarOpen = ref(false)
const socialItems = [
  {
    icon: 'fa:facebook',
    label: 'Facebook',
    link: 'https://facebook.com',
  },
  {
    icon: 'fa:instagram',
    label: 'Instagram',
    link: 'https://Instagram.com',
  },
  {
    icon: 'fa:linkedin',
    label: 'LinkedIn',
    link: 'https://Linkedin.nl',
  },
]

const navbarClasses = computed(() => [
  props.fixed && `is-fixed-top`,
  props.mode && `is-${props.mode}`,
  props.fixed && isScrolled.value && 'is-scrolled',
  isNavbarOpen.value && `is-solid`,
])

const navbarMenuClasses = computed(() => [isNavbarOpen.value && `is-active`])

const navbarItems = config.layout?.navbar?.props?.items ?? [];

const router = useRouter()

// Watch for route changes
watch(
  () => router.currentRoute.value,
  () => {
    // Close the navbar when the route changes
    isNavbarOpen.value = false
  }
)

const closeNavbar = () => {
  isNavbarOpen.value = false
}

</script>

<template>
  <nav
    class="navbar is-transparent is-centered"
    :class="navbarClasses"
  >
    <div class="container">
      <div class="navbar-brand is-vcentered">
        <RouterLink :to="{ name: 'index' }" class="navbar-item">
          <div class="navbar-logo-container">
            <ResponsiveImage
            class="navbar-logo"
            :src="config.logo.src"
            :src-dark="config.logo.srcDark"
            alt=""
            :width="config.logo.width"
            :height="config.logo.height"
          />
          </div>
        </RouterLink>
        <div
          class="navbar-burger"
          role="button"
          tabindex="0"
          @click.prevent="isNavbarOpen = !isNavbarOpen"
          @keydown.space.prevent="() => (isNavbarOpen = !isNavbarOpen)"
        >
          <Hamburger :open="isNavbarOpen" />
        </div>
      </div>
      <div
        class="navbar-menu b-centered-mobile b-centered-tablet-p"
        :class="navbarMenuClasses"
      >
        <div class="navbar-start">
          <RouterLink
            v-for="item in navbarItems"
            :key="item.label"
            :to="item.to"
            class="navbar-item has-naver"
            @click="closeNavbar"
          >
            {{ item.label }}
          </RouterLink>
        </div>
        <div class="navbar-center">
          <!-- Centered brand or additional links can go here -->
        </div>
        <div class="navbar-end">
          <!-- Theme toggles or additional items -->
          <div class="navbar-item" />
          <div class="navbar-item">
            <!-- TO DO ADD SOCIAL ITEMS ONCE LINKS ARE ONLINE -->
            <!-- <NavbarSocialItem
              v-for="(item, index) in socialItems"
              :key="index"
              :icon="item.icon"
              :label="item.label"
              :link="item.link"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<style lang="scss" scoped>
.navbar {
  height: 80px;
  width: 100vw;
  // max-width: 100%;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add this line for drop shadow

  .navbar-brand {
    height: 80px;


    .navbar-burger {
      display: none;
      align-items: center;
      justify-content: center;
      height: 100px;
      width: 100px;
      background: none;
    }
  }

  .navbar-menu {
    &.is-active {
      text-align: center;
      background: var(--navbar-bg-color);
      box-shadow: rgb(0 0 0 / 8%) 0 1px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .navbar-item {
    font-family: var(--font);
    color: var(--navbar-link-color-medium);
    transition: color 0.3s;
    background: none;
    border: none;

    &:hover {
      color: var(--primary);
    }

    &.has-naver {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform-origin: right center;
        height: 3px;
        border-radius: 50px;
        background: var(--primary);
        transform: scale(0, 1);
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        color: var(--primary);

        &::before {
          transform-origin: left center;
          transform: scale(1, 1);
        }
      }
    }

    &.has-dropdown {
      .navbar-link {
        color: var(--medium-text);

        &:hover {
          color: var(--primary);
        }
      }

      .navbar-dropdown {
        width: 240px;
      }
    }

    > .button {
      min-height: 44px;
      min-width: 110px;
    }
  }

  &.is-fade {
    background: transparent;

    &.is-scrolled {
      background: var(--navbar-bg-color);
      box-shadow: rgb(0 0 0 / 8%) 0 1px 12px;

      .navbar-item {
        color: var(--navbar-link-color-medium);
      }
    }
  }

  &.is-inverted {
    background: transparent;

    .navbar-item {
      color: var(--navbar-link-color-inverted);
    }

    &.is-scrolled {
      background: var(--navbar-bg-color);
      box-shadow: rgb(0 0 0 / 8%) 0 1px 12px;

      .navbar-item {
        color: var(--navbar-link-color-medium);
      }
    }
  }

  &.is-solid {
    background: var(--navbar-bg-color);
    box-shadow: none;

    .navbar-menu {
      border-bottom: 1px solid var(--navbar-border-color);

      .navbar-item {
        color: var(--navbar-link-color-medium);
      }
    }
  }

  &.is-centered {
    .navbar-menu {
      justify-content: space-between;

      .navbar-start,
      .navbar-end {
        width: 20%;
      }

      .navbar-start {
        .left-brand {
          display: flex;
          align-items: center;

          .navbar-item {
            img {
              display: block;
              width: 100%;
              max-width: 140px;
              max-height: 38px;
            }
          }
        }
      }

      .navbar-center {
        flex-grow: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .centered-brand {
          .navbar-item {
            img {
              display: block;
              width: 100%;
              max-width: 140px;
              max-height: 38px;
              margin: 0 auto;
            }
          }
        }

        .navbar-item {
          &.is-search {
            flex-grow: 2;
            max-width: 450px;
            margin: 0 auto;

            .control {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (width <= 980px) {
  .navbar {
    .navbar-brand {
      .navbar-burger {
        display: flex !important;
      }
    }
  }
}

@media only screen and (width <= 767px) {
  .navbar {
    .navbar-brand {
      .navbar-burger {
        display: flex;
      }
    }

    .navbar-menu {
      .navbar-start,
      .navbar-end {
        width: 100% !important;
      }
    }

    .navbar-item {
      &.has-naver {
        &::before {
          display: none !important;
        }
      }

      &.has-dropdown {
        .navbar-dropdown {
          width: 100%;
        }
      }

      .button {
        min-height: 46px;
        width: 100%;
      }
    }
  }
}

@media only screen and (width >= 768px) and (width <= 1024px) and (orientation: portrait) {
  .navbar {
    .navbar-brand {
      .navbar-burger {
        display: flex;
      }
    }

    .navbar-menu {
      max-width: 100%;

      .navbar-start,
      .navbar-end {
        width: 100% !important;
      }
    }

    .navbar-item {
      &.has-dropdown {
        .navbar-dropdown {
          width: 100%;
        }
      }

      .button {
        min-height: 46px;
        width: 100%;
      }
    }
  }
}

@media only screen and (width <= 1024px) {
  .navbar {
    &.is-centered {
      &:not(.no-nav) {
        .navbar-brand {
          display: flex;
        }

        .navbar-menu {
          .navbar-center {
            display: none !important;
          }
        }
      }

      &.no-nav {
        .navbar-menu {
          display: flex !important;
          padding: 0 !important;
          box-shadow: none !important;
          background: transparent !important;
        }
      }
    }
  }
}

@media only screen and (height <= 500px) {
  .navbar.navbar {
    height: 60px; // Increasing specificity
    .navbar-brand.navbar-brand, .navbar-burger.navbar-burger {
      height: 60px; // Increasing specificity
    }
  }
}

</style>
