import type {
  SimpleFeature,
  IconFeature,
  BlobFeature,
  ImageFeature,
} from '/@src/types'

export const features: IconFeature[] = [
  {
    title: 'Campaigns',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'primary',
    icon: 'feather:mail',
    link: '/',
  },
  {
    title: 'Task Management',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'green',
    icon: 'feather:briefcase',
    link: '/',
  },
  {
    title: 'Personal Dashboard',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'info',
    icon: 'feather:grid',
    link: '/',
  },
  {
    title: 'Payment Gateways',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'purple',
    icon: 'feather:credit-card',
    link: '/',
  },
  {
    title: 'Time Management',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'yellow',
    icon: 'feather:clock',
    link: '/',
  },
  {
    title: 'Notifications',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'red',
    icon: 'feather:bell',
    link: '/',
  },
  {
    title: 'Social Integrations',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'success',
    icon: 'feather:facebook',
    link: '/',
  },
  {
    title: 'Instant Messaging',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    color: 'orange',
    icon: 'feather:message-circle',
    link: '/',
  },
]

export const features2: ImageFeature[] = [
  {
    title: 'Campaigns',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/laptop-mail.svg',
    ResponsiveImage: '/assets/icons/colored/laptop-mail.svg',
    link: '/',
  },
  {
    title: 'Task Management',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/papers.svg',
    ResponsiveImage: '/assets/icons/colored/papers.svg',
    link: '/',
  },
  {
    title: 'Personal Dashboard',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/compass.svg',
    ResponsiveImage: '/assets/icons/colored/compass.svg',
    link: '/',
  },
  {
    title: 'Payment Gateways',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/credit-card.svg',
    ResponsiveImage: '/assets/icons/colored/credit-card.svg',
    link: '/',
  },
  {
    title: 'Time Management',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/clock.svg',
    ResponsiveImage: '/assets/icons/colored/clock.svg',
    link: '/',
  },
  {
    title: 'Notifications',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/bell.svg',
    ResponsiveImage: '/assets/icons/colored/bell.svg',
    link: '/',
  },
  {
    title: 'Social Integrations',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/puzzle.svg',
    ResponsiveImage: '/assets/icons/colored/puzzle.svg',
    link: '/',
  },
  {
    title: 'Instant Messaging',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/icons/colored/chat.svg',
    ResponsiveImage: '/assets/icons/colored/chat.svg',
    link: '/',
  },
]

export const features3: SimpleFeature[] = [
  {
    title: 'Monthly reports',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    link: '/',
  },
  {
    title: 'Pro Dashboard',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    link: '/',
  },
]

export const features4: SimpleFeature[] = [
  {
    title: 'Fast delivery',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    link: '/',
  },
  {
    title: 'Tracking app',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    link: '/',
  },
  {
    title: 'Saved orders',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    link: '/',
  },
  {
    title: 'Private sales',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    link: '/',
  },
]

export const features5: IconFeature[] = [
  {
    title: 'Stay Relaxed',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    color: 'primary',
    icon: 'feather:coffee',
    link: '/',
  },
  {
    title: 'Save Time',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    color: 'green',
    icon: 'feather:clock',
    link: '/',
  },
  {
    title: 'Earn More',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    color: 'info',
    icon: 'feather:zap',
    link: '/',
  },
]

export const features6: ImageFeature[] = [
  {
    title: '24/7 Support',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/illustrations/features/standard-feature-1.svg',
    ResponsiveImage: '/assets/illustrations/features/standard-feature-1.svg',
    link: '/',
  },
  {
    title: 'Instant Geolocation',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/illustrations/features/standard-feature-2.svg',
    ResponsiveImage: '/assets/illustrations/features/standard-feature-2.svg',
    link: '/',
  },
  {
    title: 'Social Integrations',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus. Hoc est non dividere, sed frangere.',
    image: '/assets/illustrations/features/standard-feature-3.svg',
    ResponsiveImage: '/assets/illustrations/features/standard-feature-3.svg',
    link: '/',
  },
]

export const features7: ImageFeature[] = [
  {
    title: 'Bediening via app',
    text: 'Met de ingebouwde wifi-module kun je de airco bedienen via de app op je smartphone.',
    image: '/assets/illustrations/features-appbediening.svg',
    ResponsiveImage: '/assets/illustrations/features-appbediening.svg',
    link: '/',
  },
  {
    title: 'Fluisterstil!',
    text: 'Op maximaal vermogen produceert de binnen-unit minder dan 25 dB aan geluid. Dat is stiller dan een bibliotheek!',
    image: '/assets/illustrations/features-fluisterstil.svg',
    ResponsiveImage: '/assets/illustrations/features-fluisterstil.svg',
    link: '/',
  },
  {
    title: '3D uitblaas-technologie',
    text: 'Clima Plus airco\'s lucht in een driedimensionaal patroon, waardoor de luchtstroom gelijkmatig verdeeld wordt over de ruimte.',
    image: '/assets/illustrations/features-3duitblaas.svg',
    ResponsiveImage: '/assets/illustrations/features-3duitblaas.svg',
    link: '/',
  }
]
export const features8: SimpleFeature[] = [
  {
    title: '3.5kW / 12.000 BTU',
    text: 'Geschikt voor ruimtes tot ongeveer 90-100 kubieke meter (ongeveer 30-35 vierkante meter met standaard plafondhoogtes). Deze unit is ideaal voor slaapkamers, kleine woonkamers of kleine kantoren.',
    link: '/producten',
    hash: '35kw',
  },
  {
    title: '5kW / 18.000 BTU',
    text: 'Een populaire keuze! De 5kW variant biedt klimaatbeheersing voor grotere woonkamers, middelgrote kantoren of open ruimtes! Ideaal voor grotere ruimtes tot 140-150 kubieke meter (ongeveer 45-55 vierkante meter).',
    link: '/producten',
    hash: '5kw',
  },
  {
    title: '7kw / 24.000 BTU',
    text: 'Ontworpen voor ruimtes tot 190-200 kubieke meter (60-75 vierkante meter). Deze unit is geschikt voor grotere ruimtes zoals open woonruimtes, grotere kantoren of kleine commerciële ruimtes.',
    link: '/producten',
    hash: '7kw',
  },
  {
    title: 'Duo Split',
    text: 'Een oplossing waarbij je twee aparte ruimtes - tot 100 kubieke meter per kamer - tegelijkertijd en afzonderlijk kunt verkoelen of verwarmen. Ideaal voor als u niet te veel buiten-units een plek wilt geven op uw gevel, dak of grond.',
    link: '/producten',
    hash: 'duo',
  },
]

export const features9: BlobFeature[] = [
  {
    subtitle: 'Teamwork',
    title: 'Collaborate easily',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bonum patria: miserum exilium. Duo Reges: constructio interrete. Vitiosum est enim in dividendo partem in genere numerare. Restinguet citius, si ardentem acceperit. Invidiosum nomen est, infame, suspectum.',
    image: '/assets/images/buiten35kw.png',
    link: '/',
  },
  {
    subtitle: 'Efficiency',
    title: 'Save tons of time',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bonum patria: miserum exilium. Duo Reges: constructio interrete. Vitiosum est enim in dividendo partem in genere numerare. Restinguet citius, si ardentem acceperit. Invidiosum nomen est, infame, suspectum.',
    image: 'data:image/gif;base64,replace_with_your_image',
    link: '/',
  },
  {
    subtitle: 'Satisfaction',
    title: 'Get the job done',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bonum patria: miserum exilium. Duo Reges: constructio interrete. Vitiosum est enim in dividendo partem in genere numerare. Restinguet citius, si ardentem acceperit. Invidiosum nomen est, infame, suspectum.',
    image: 'data:image/gif;base64,replace_with_your_image',
    link: '/',
  },
]
