<script setup lang="ts">
// import config from '/@src/app.config'

export type FooterColor = 'light' | 'dark'

export interface FooterLink {
  label: string
  to: string
}

export interface FooterSocial {
  icon: string
  link: string
  name: string
}

export interface FooterColumn {
  title: string
  links: FooterLink[]
}

export interface FooterCtaBenefit {
  label: string
}

export interface FooterCta {
  title: string
  subtitle: string
  benefits: FooterCtaBenefit[]
  links: FooterLink[]
}

export interface Footer {
  columns: FooterColumn[]
  socials: FooterSocial[]
  newsletter?: {
    action?: string
    method?: string
  }
  links: FooterLink[]
  cta?: FooterCta
  copyright: string
}

export interface FooterProps {
  content: Footer
  color?: FooterColor
}

const props = withDefaults(defineProps<FooterProps>(), {
  color: 'light',
})

const footerClasses = computed(() => [
  props.color && `footer-${props.color}`,
  props.content.cta && `has-cta`,
])
</script>

<template>
  <FooterA

  >
    <template #left-links>
      <RouterLink
        :to="{ name: 'producten' }"
        class="level-item footer-link"
      >
        Producten
      </RouterLink>
      <RouterLink
        :to="{ name: 'onderhoud' }"
        class="level-item footer-link"
      >
        Onderhoud
      </RouterLink>
      <RouterLink
        :to="{ name: 'faq' }"
        class="level-item footer-link"
      >
        FAQ
      </RouterLink>
    </template>
    <template #right-links>
      <RouterLink
        :to="{ name: 'contact' }"
        class="level-item footer-link"
      >
        Contact
      </RouterLink>
      <RouterLink
        :to="{ name: 'blog' }"
        class="level-item footer-link"
      >
        Blog
      </RouterLink>
      <RouterLink
        :to="{ name: 'terms' }"
        class="level-item footer-link"
      >
        Algemene voorwaarden
      </RouterLink>
    </template>
  </FooterA>
</template>

<style lang="scss" scoped>
.footer {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 3rem;

  &.has-cta {
    margin-top: 6rem;
    padding-top: 10rem;
  }

  &.footer-primary {
    background: var(--primary);
  }

  &.footer-light {
    background: var(--footer-light-bg-color);
  }

  &.footer-dark {
    background: var(--footer-dark-bg-color);

    .columns {
      &.is-bordered {
        border-bottom: 1px solid var(--footer-dark-border-color);
      }
    }

    .title {
      color: var(--footer-dark-heading-color);
      opacity: 0.6;
    }

    .footer-link {
      color: var(--footer-dark-link-color);
      opacity: 0.8;

      &:hover {
        color: var(--footer-dark-link-color-hover) !important;
        opacity: 1;
      }
    }
  }

  .columns {
    position: relative;
    z-index: 1;

    &.is-bordered {
      border-bottom: 1px solid var(--border);
    }
  }

  .footer-column {
    .footer-column-list {
      li {
        padding: 0.25rem 0;
      }
    }
  }

  .footer-link {
    font-family: var(--font);
    font-weight: 300;
    color: var(--footer-light-link-color);
    transition: color 0.3s;
    padding: 0.25rem 0;

    &:hover {
      color: var(--footer-light-link-color-hover);
    }
  }

  .social-block {
    .socials {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem 0;

      a,
      .router-link {
        color: var(--white);
        font-size: 1rem;
        margin: 0 0.5rem;
      }
    }
  }

  .footer-logo-left {
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 100%;
      max-width: 36px;
    }

    .footer-logo-text {
      font-family: var(--font-alt);
      font-weight: 300;
      font-size: 1rem;
      text-transform: uppercase;
      margin-left: 0.5rem;
      margin-right: 1.5rem;
      color: var(--white);
    }
  }
}

@media only screen and (width <= 767px) {
  .footer {
    &.has-cta {
      margin-top: 12rem;
    }

    .social-block .socials {
      justify-content: center;
    }
  }
}

@media only screen and (width >= 768px) and (width <= 1024px) and (orientation: portrait) {
  .footer {
    .footer-columns {
      display: flex;
      padding-bottom: 0 !important;
    }

    .columns.pt-6.pb-6 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  }
}
</style>
