export const baseTestimonials = [
    {
      content: "We hebben onlangs de 3.5kW unit van Clima-Plus geïnstalleerd in onze woonkamer. Het systeem werkt perfect en houdt ons huis zelfs tijdens de warmste dagen heerlijk koel. De installatie was snel en zonder overlast.",
      avatar: 'data:image/gif;base64,replace_with_your_image',
      name: 'Henk Jansen',
      position: 'Eigenaar, Café Het Noorden'
  },
  {
      content: "De Duo Split modellen van Clima-Plus zijn een gamechanger voor ons bedrijf geweest. De mogelijkheid om verschillende temperaturen in meerdere ruimtes te regelen heeft iedereen tevreden gesteld. Zeer aan te raden!",
      avatar: 'data:image/gif;base64,replace_with_your_image',
      name: 'Sara de Vries',
      position: 'Facilitair Manager'
  },
  {
      content: "Na een grondige vergelijking hebben we voor Clima-Plus gekozen vanwege hun energiezuinige 3.5kW systemen. We zijn niet alleen blij met de lagere energierekening, maar ook met de stille werking van het apparaat.",
      avatar: 'data:image/gif;base64,replace_with_your_image',
      name: 'Linda Mulder',
      position: 'Hoofd Inkoop'
  },
  {
      content: "Ik was op zoek naar een efficiënte en betrouwbare airco-oplossing voor ons nieuwe kantoor. De Duo Split van Clima-Plus was precies wat we nodig hadden. Het installatieteam was professioneel en liet alles netjes achter.",
      avatar: 'data:image/gif;base64,replace_with_your_image',
      name: 'Anika Groen',
      position: 'Bedrijfsanalist'
  },

  {
    content:
      "It's a really cool network. Like, we had a fight with Richard at one point, and I think they deleted some of my data, but then they brought it back. Just bro's being bro's.",
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Ross Martin',
    position: 'Product Manager',
  },
  {
    content:
      "It's a really cool network. Like, we had a fight with Richard at one point, and I think they deleted some of my data, but then they brought it back. Just bro's being bro's.",
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Miranda Wallace',
    position: 'UX Designer',
  },
  {
    content:
      "It's a really cool network. Like, we had a fight with Richard at one point, and I think they deleted some of my data, but then they brought it back. Just bro's being bro's.",
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Anna Klecenszki',
    position: 'Project Manager',
  },
  {
    content:
      "It's a really cool network. Like, we had a fight with Richard at one point, and I think they deleted some of my data, but then they brought it back. Just bro's being bro's.",
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Taha El Maliki',
    position: 'Business Developer',
  },
]

export const boxedTestimonials = [
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Henry Statterman',
    position: 'Sales Manager',
    logo: '/assets/brands/infinite.svg',
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Joey Bagara',
    position: 'Project Manager',
    logo: '/assets/brands/proactive.svg',
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Linda Stinson',
    position: 'Art Director',
    logo: '/assets/brands/tribe.svg',
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Anika Geller',
    position: 'Business Analyst',
    logo: '/assets/brands/kromo.svg',
  },
]

export const boxedTestimonials2 = [
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Yenela Paloupakis',
    position: 'Solution Architect',
    logo: '/assets/brands/infinite.svg',
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: "Kenneth O'Hara",
    position: 'Project Manager',
    logo: '/assets/brands/proactive.svg',
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Melinda Callaway',
    position: 'Customer Support',
    logo: '/assets/brands/tribe.svg',
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Eddie Finch',
    position: 'Business Analyst',
    logo: '/assets/brands/kromo.svg',
  },
]

export const socialTestimonials = [
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Yenela Paloupakis',
    position: 'Solution Architect',
    logo: '/assets/brands/infinite.svg',
    socialLinks: [
      {
        name: 'Linkedin',
        icon: 'feather:linkedin',
        url: 'https://linkedin.com',
      },
      {
        name: 'Twitter',
        icon: 'feather:twitter',
        url: 'https://twitter.com',
      },
      {
        name: 'GitHub',
        icon: 'feather:github',
        url: 'https://github.com',
      },
    ],
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: "Kenneth O'Hara",
    position: 'Project Manager',
    logo: '/assets/brands/proactive.svg',
    socialLinks: [
      {
        name: 'Linkedin',
        icon: 'feather:linkedin',
        url: 'https://linkedin.com',
      },
      {
        name: 'Twitter',
        icon: 'feather:twitter',
        url: 'https://twitter.com',
      },
      {
        name: 'GitHub',
        icon: 'feather:github',
        url: 'https://github.com',
      },
    ],
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Melinda Callaway',
    position: 'Customer Support',
    logo: '/assets/brands/tribe.svg',
    socialLinks: [
      {
        name: 'Linkedin',
        icon: 'feather:linkedin',
        url: 'https://linkedin.com',
      },
      {
        name: 'Twitter',
        icon: 'feather:twitter',
        url: 'https://twitter.com',
      },
      {
        name: 'GitHub',
        icon: 'feather:github',
        url: 'https://github.com',
      },
    ],
  },
  {
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Idem iste, inquam, de voluptate quid sentit? Hanc quoque iucunditatem, si vis, transfer in animum; Erat enim Polemonis. Apparet statim, quae sint officia.',
    avatar: 'data:image/gif;base64,replace_with_your_image',
    name: 'Eddie Finch',
    position: 'Business Analyst',
    logo: '/assets/brands/kromo.svg',
    socialLinks: [
      {
        name: 'Linkedin',
        icon: 'feather:linkedin',
        url: 'https://linkedin.com',
      },
      {
        name: 'Twitter',
        icon: 'feather:twitter',
        url: 'https://twitter.com',
      },
      {
        name: 'GitHub',
        icon: 'feather:github',
        url: 'https://github.com',
      },
    ],
  },
]
