import type { BaseFeature, SimpleIconFeature } from '/@src/types'

export const commerceAppFeatures: BaseFeature[] = [
  {
    title: 'Makkelijk in gebruik',
    text: 'Onze app is ontworpen om eenvoudig te gebruiken en te begrijpen.',
  },
  {
    title: 'Prijs / Kwaliteit verhouding',
    text: 'Onze prijskwaliteit verhouding is ongeëvenaard. Wij bieden de beste prijs voor de beste kwaliteit.',
  },
  {
    title: 'Ontworpen in Nederland',
    text: 'Wij zijn een Nederlands bedrijf en ontwerpen onze producten in Nederland.',
  },
]

export const salesFeatures: SimpleIconFeature[] = [
  {
    title: 'Inclusief Wifi',
    text: 'Met de ingebouwde wifi module kan je de airco bedienen via je smartphone. Dat is wel zo gemakkelijk!',
    icon: 'ion:wifi-outline',
  },
  {
    title: '3D uitblaas-technologie',
    text: 'Omdat ons systeem de lucht in 3D blaast, wordt de ruimte sneller gekoeld en verwarmd.',
    icon: 'ion:medical-outline',
  },
  {
    title: 'Fluisterstil met maximaal 25dB',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:leaf-outline',
  },
  {
    title: 'Tot 5 jaar garantie!',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:calendar-number-outline',
  },
]

export const growthFeatures: SimpleIconFeature[] = [
  {
    title: 'Email marketing',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:mail-unread-outline',
  },
  {
    title: 'Bundles & upsells',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:sparkles-outline',
  },
  {
    title: 'Lead magnets',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:magnet-outline',
  },
  {
    title: 'Discount codes',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:gift-outline',
  },
]

export const businessFeatures: SimpleIconFeature[] = [
  {
    title: 'Email marketing',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:mail-unread-outline',
  },
  {
    title: 'Bundles & upsells',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:sparkles-outline',
  },
  {
    title: 'Lead magnets',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:magnet-outline',
  },
  {
    title: 'Discount codes',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:gift-outline',
  },
]

export const managementFeatures: SimpleIconFeature[] = [
  {
    title: 'All-in-one toolkit',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:construct-outline',
  },
  {
    title: 'Business insights',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:speedometer-outline',
  },
  {
    title: 'Invoicing',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:reader-outline',
  },
  {
    title: 'Tax compliance',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed videbimus.',
    icon: 'ion:document-attach-outline',
  },
]

export const testimonials = [
  {
    icon: 'et:briefcase',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille. Eam tum adesse, cum dolor omnis absit',
    testifier: {
      name: 'Danny Stokes',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Covenant',
    },
  },
  {
    icon: 'et:adjustments',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum.',
    testifier: {
      name: 'Bella Walid',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Kromo',
    },
  },
  {
    icon: 'et:trophy',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille.',
    testifier: {
      name: 'Johanna Miller',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Grubspot',
    },
  },
  {
    icon: 'et:lightbulb',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille.',
    testifier: {
      name: 'Edward Kolst',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Proactive',
    },
  },
  {
    icon: 'et:layers',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille. Eam tum adesse, cum dolor omnis absit',
    testifier: {
      name: 'Mike Fraiser',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Infinite',
    },
  },
  {
    icon: 'et:browser',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum.',
    testifier: {
      name: 'Armin Azadian',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Tribe',
    },
  },
  {
    icon: 'et:camera',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille. Eam tum adesse.',
    testifier: {
      name: 'Sandra Muller',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Livetalk',
    },
  },
  {
    icon: 'et:compass',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille.',
    testifier: {
      name: 'Eddy Williams',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Vonmo',
    },
  },
  {
    icon: 'et:documents',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum. Quae cum dixisset, finem ille. Eam tum adesse, cum dolor omnis absit',
    testifier: {
      name: 'Navad Prasad',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Brookers',
    },
  },
  {
    icon: 'et:chat',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quamquam te quidem video minime esse deterritum.',
    testifier: {
      name: 'Christina Chu',
      picture: 'data:image/gif;base64,replace_with_your_image',
      role: 'Founder @Bitbreaker',
    },
  },
]

export const posts = [
  {
    id: 0,
    image: '/assets/images/blog/blog1thumbnail.png',
    title: 'Waarom verwarmen met een airco een goed idee is',
    likes: '112',
    categories: [
      {
        name: 'Commerce',
      },
      {
        name: 'Conversion',
      },
      {
        name: 'Profit',
      },
    ],
    date: 'March 17th, 2022',
    duration: 2,
  },
  {
    id: 1,
    image: '/assets/images/blog/blogthumbnail2.png',
    title: 'How order bumps can help increasing your revenue and conversions',
    likes: '319',
    categories: [
      {
        name: 'Orders',
      },
      {
        name: 'Checkout',
      },
      {
        name: 'Conversion',
      },
    ],
    date: 'March 17th, 2022',
    duration: 2,
  },
  {
    id: 2,
    image: '/assets/images/blog/blogthumbnail3.png',
    title: 'How a rich and well maintained catalogue easily converts customers',
    likes: '519',
    categories: [
      {
        name: 'Conversion',
      },
      {
        name: 'Sales',
      },
      {
        name: 'Products',
      },
    ],
    date: 'March 17th, 2022',
    duration: 2,
  },
]

export const customerLogos = [
  {
    id: 0,
    logo: '/assets/brands/stek-logo.svg',
  },
  {
    id: 1,
    logo: '/assets/brands/stek-logo.svg',
  },
  {
    id: 2,
    logo: '/assets/brands/stek-logo.svg',
  },
  {
    id: 3,
    logo: '/assets/brands/stek-logo.svg',
  }
]

export const faq = {
  left: [
    {
      title: 'How much does it cost?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'Does it really work anywhere?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'How does it differ from other tools?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
  ],
  right: [
    {
      title: 'How does it work?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'Can I try it for free?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
    {
      title: 'How many agents are allowed?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis est tam dissimile homini. Quae diligentissime contra Aristonem dicuntur a Chryippo. Qua tu etiam inprudens utebare non numquam. Paria sunt igitur. Idemne, quod iucunde.',
    },
  ],
}
