<script setup lang="ts">
import type { SimpleFeature } from '/@src/types'

export interface FeatureBlockJProps {
  title: string
  content: string
  features: SimpleFeature[]
  image: string
  ResponsiveImage: string
  links?: boolean
  inverted?: boolean
}

const props = withDefaults(defineProps<FeatureBlockJProps>(), {
  links: false,
  inverted: false,
})

const textClasses = computed(() => [props.inverted && 'text-light'])

// Ensure that the feature object includes an 'id' property
export interface SimpleFeature {
  title: string
  text: string
  link: string
  hash: string // Changed from 'id' to 'hash'
}

</script>

<template>
  <div>
    <div class="columns is-vcentered mb-6">
      <div class="column is-5 ">
        <slot name="content">
          <Title
            tag="h2"
            :size="3"
            weight="semi"
            leading
            :inverted="props.inverted"
          >
            <span>{{ props.title }}</span>
          </Title>
          <p
            class="paragraph max-w-tp-full medium:max-w-4 mb-4 mt-4 rem-150"
            :class="textClasses"
          >
            {{ props.content }}
          </p>
        </slot>
      </div>
      <div class="column is-6 is-offset-1">
        <slot name="image">
          <ResponsiveImage
            :src="props.image"
            :src-dark="props.ResponsiveImage"
            :class="['mx-auto']"
            alt=""
          />
        </slot>
      </div>
    </div>

    <!-- 4 Opties onderaan -->
    <div class="columns b-columns-half-tablet-p is-justify-content-space-between mt-6 pt-4">
      <div 
        v-for="(feature, index) in props.features.slice(0, 4)"
        :key="index"
        class="column is-3 columns min-height-250 px-5 mb-5"
      >
        <div 
          class="py-2 medium:py-4 columns is-justify-content-space-between is-flex-direction-column"
        >
          <Title
            tag="h3"
            :size="4"
            weight="semi"
            narrow
            :inverted="props.inverted"
          >
            <span>{{ feature.title }}</span>
          </Title>
          <p
            class="paragraph is-size-6 pt-2 mb-5 mt-5 rem-125"
            :class="textClasses"
          >
            {{ feature.text }}
          </p>
          <ActionLink
            v-if="props.links"
            :link="`/producten#${feature.hash}`"
            size="medium"
            class="button is-link custom-button mb-6"
          >
            Meer info
          </ActionLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.action-link) {
  margin-top: auto; // This will push the button to the bottom if the content heights vary
}

.min-height-210 {
  height: 210px;

  @media (max-width: 769px) { // Using 769px to target views below 770px
    height: 150px;
  }
}

.custom-button {
  background-color: #FF6161 !important;
  border-color: #FF6161 !important;
  font-size: 1.1rem !important;
  padding: 0.75rem 1.5rem !important;
  width: 100%;
}

.custom-button:hover {
  background-color: #ff4747 !important; /* Slightly darker shade for hover effect */
  border-color: #ff4747 !important;
}

.centered-title {
  text-align: center;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

p.paragraph {
  text-align: left;
  align-self: flex-start;
}

.min-height-250 {
  min-height: 250px;
}
</style>