const __pages_import_0__ = () => import("/src/pages/terms/index.vue");
const __pages_import_1__ = () => import("/src/pages/producten/index.vue");
const __pages_import_2__ = () => import("/src/pages/privacy/index.vue");
const __pages_import_3__ = () => import("/src/pages/onderhoud/index.vue");
const __pages_import_4__ = () => import("/src/pages/blog/waarom-verwarmen-met-een-airco-een-goed-idee-is.vue");
const __pages_import_5__ = () => import("/src/pages/blog/index.vue");
import __pages_import_6__ from "/src/pages/index.vue";
const __pages_import_7__ = () => import("/src/pages/[...all].vue");

const routes = [{"name":"terms","path":"/terms","component":__pages_import_0__,"props":true},{"name":"producten","path":"/producten","component":__pages_import_1__,"props":true},{"name":"privacy","path":"/privacy","component":__pages_import_2__,"props":true},{"name":"onderhoud","path":"/onderhoud","component":__pages_import_3__,"props":true},{"name":"blog-waarom-verwarmen-met-een-airco-een-goed-idee-is","path":"/blog/waarom-verwarmen-met-een-airco-een-goed-idee-is","component":__pages_import_4__,"props":true},{"name":"blog","path":"/blog","component":__pages_import_5__,"props":true,"meta":{"footer":{"enabled":false}}},{"name":"index","path":"/","component":__pages_import_6__,"props":true,"meta":{"footer":{"enabled":false}}},{"name":"all","path":"/:all(.*)*","component":__pages_import_7__,"props":true,"meta":{"navbar":{"enabled":false},"footer":{"enabled":false}}}];

export default routes;