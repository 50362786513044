<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useHead } from '@unhead/vue'
import apiService from '/@src/services/api'
import '@iconify/iconify' // Ensure iconify is imported

interface FormData {
  name: string
  email: string
  message: string
}

const formData = ref<FormData>({
  name: '',
  email: '',
  message: ''
})

const isSubmitting = ref(false)
const submitSuccess = ref(false)
const errorMessage = ref('')
const formError = ref(false)
const formRef = ref<HTMLFormElement | null>(null)

// Field-specific error states
const nameError = ref(false)
const emailError = ref(false)
const messageError = ref(false)

// Add this new ref for the checkbox
const privacyAgreed = ref(false)
const privacyError = ref(false) // Add field-specific error state for privacy

const emit = defineEmits<{
  (e: 'form-submit', success: boolean, message?: string): void
}>()

onMounted(() => {
  if (formRef.value) {
    const inputs = formRef.value.querySelectorAll('input, textarea')
    inputs.forEach(input => {
      input.addEventListener('invalid', (event) => {
        const target = event.target as HTMLInputElement
        if (target.validity.valueMissing) {
          target.setCustomValidity('Dit veld is verplicht.')
        } else if (target.validity.typeMismatch && target.type === 'email') {
          target.setCustomValidity('Voer een geldig e-mailadres in.')
        }
      })
      input.addEventListener('input', (event) => {
        const target = event.target as HTMLInputElement
        target.setCustomValidity('')
      })
    })
  }
})

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

const validateForm = (): boolean => {
  let isValid = true
  nameError.value = false
  emailError.value = false
  messageError.value = false
  privacyError.value = false // Reset privacy error state

  if (!formData.value.name.trim()) {
    errorMessage.value = 'Naam is verplicht'
    nameError.value = true
    isValid = false
  }
  if (!formData.value.email.trim() || !validateEmail(formData.value.email)) {
    errorMessage.value = 'Vul een geldig e-mailadres in'
    emailError.value = true
    isValid = false
  }
  if (!formData.value.message.trim()) {
    errorMessage.value = 'Bericht is verplicht'
    messageError.value = true
    isValid = false
  }
  if (!privacyAgreed.value) {
    errorMessage.value = 'U moet akkoord gaan met de privacyverklaring'
    privacyError.value = true // Set privacy error state
    isValid = false
  }
  return isValid
}

const handleSubmit = async () => {
  if (validateForm()) {
    isSubmitting.value = true
    errorMessage.value = ''
    try {
      const response = await apiService.submitContactForm(formData.value)
      console.log('Server response:', response)
      submitSuccess.value = true
      emit('form-submit', true)
    } catch (error: any) {
      console.error('Error submitting form:', error)
      errorMessage.value = 'Er is een fout opgetreden. Probeer het opnieuw.'
      emit('form-submit', false, errorMessage.value)
    } finally {
      isSubmitting.value = false
    }
  } else {
    formError.value = true
    setTimeout(() => {
      formError.value = false
    }, 820)
  }
}

const resetForm = () => {
  formData.value = {
    name: '',
    email: '',
    message: ''
  }
  submitSuccess.value = false
  nameError.value = false
  emailError.value = false
  messageError.value = false
}
</script>

<template>
  <div 
    class="contact-form-container mt-6"
    :class="{ 'error-outline shake-animation': formError, 'flipped': submitSuccess }"
  >
    <div class="flipper">
      <div class="front">
        <div class="form-container">
          <form
            v-if="!submitSuccess"
            ref="formRef"
            novalidate
            @submit.prevent="handleSubmit"
          >
            <div class="form-group">
              <input
                v-model="formData.name"
                type="text"
                placeholder="Uw naam"
                required
                :class="{ 'input-error': nameError }"
              >
            </div>
            <div class="form-group">
              <input
                v-model="formData.email"
                type="email"
                placeholder="Uw email"
                required
                :class="{ 'input-error': emailError }"
              >
            </div>
            <div class="form-group">
              <textarea
                v-model="formData.message"
                placeholder="Uw bericht..."
                required
                :class="{ 'input-error': messageError }"
              />
            </div>
            <div class="form-group privacy-checkbox">
              <input
                type="checkbox"
                id="privacy-agreement"
                v-model="privacyAgreed"
                :class="{ 'input-error': privacyError }"
                required
              >
              <label for="privacy-agreement">
                Door dit formulier in te dienen, gaat u akkoord met onze 
                <a href="/privacy" target="_blank" class="privacy-link">
                  privacyverklaring
                  <span class="iconify" data-icon="mdi:open-in-new"></span>
                </a>.
              </label>
            </div>
            <div
              v-if="errorMessage"
              class="error-message"
            >
              {{ errorMessage }}
            </div>
            <button
              type="submit"
              :disabled="isSubmitting"
              :class="{ 'is-loading': isSubmitting, 'shake-animation': privacyError }"
            >
              {{ isSubmitting ? 'Verzenden...' : 'Versturen' }}
            </button>
          </form>
        </div>
        <div class="image-container">
          <img
            src="/assets/illustrations/man-hot.png"
            alt="Clima Plus"
            class="placeholder-image"
          >
        </div>
      </div>
      <div class="back">
        <div class="success-message">
          <div class="image-container">
            <img
              src="/assets/illustrations/woman-cool-couch.png"
              alt="Bedankt"
              class="thank-you-image pt-6 mb-4"
            >
          </div>
          <div class="text-container mb-6">
            <h2>Bedankt voor uw bericht!</h2>
            <p>We nemen zo spoedig mogelijk contact met u op.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contact-form-container {
  perspective: 1000px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 600px;
  background-color: #CFE0FF;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  &.error-outline {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    box-shadow: 0 0 0 2px var(--danger);
  }

  &.flipped .flipper {
    transform: rotateY(180deg);
  }

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    flex: 1;
    display: flex;
    height: 100%;
  }

  .front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container, .form-container {
    flex: 1;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-container {
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .form-container {
    form {
      width: 100%;
      max-width: 600px;
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  input, textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 16px;
    background-color: white;

    &:focus {
      outline: none;
      border-color: #89AFE5;
    }

    &.input-error {
      box-shadow: 0 0 0 1px var(--danger);
    }
  }

  textarea {
    height: 150px;
    resize: vertical;
  }

  .error-message {
    color: var(--danger);
    margin-bottom: 15px;
  }

  button {
    width: 100%;
    padding: 15px;
    background-color: #FF6161;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:disabled {
      background-color: #FF6161; // Keep the button color the same
      cursor: pointer; // Allow the button to be clickable
    }

    &.is-loading {
      opacity: 0.7;
    }

    &.shake-animation {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    }
  }

  .success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;

    .image-container {
      width: 100%;
      max-width: 600px;
      margin-bottom: 20px;

      .thank-you-image {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .text-container {
      text-align: center;

      h2 {
        font-size: 32px;
        color: #4CAF50;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        color: #666;
      }
    }
  }

  @keyframes shake {
    10%, 90% { transform: translate3d(-1px, 0, 0); }
    20%, 80% { transform: translate3d(2px, 0, 0); }
    30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
    40%, 60% { transform: translate3d(4px, 0, 0); }
  }

  @media (max-width: 768px) {
    height: auto;
    min-height: 400px;

    .flipper {
      transform-style: flat;
      height: 100%;
    }

    .front, .back {
      position: relative;
      transform: none;
      flex-direction: column;
      height: 100%;
    }

    .front {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .image-container {
        display: none;
      }
    }

    .back {
      display: none;
      transform: none !important; // Ensure no transform is applied on mobile
    }

    &.flipped {
      .front {
        display: none;
      }
      .back {
        display: flex;
      }
      .flipper {
        transform: none; // Remove the flip animation for mobile
      }
    }

    .form-container {
      padding: 20px;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    input, textarea, button {
      font-size: 14px;
    }

    textarea {
      height: 100px;
    }

    .success-message {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .image-container {
        display: block;
        max-width: 100%;
        max-height: 200px;
        padding: 20px;
        margin-top: 0;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .text-container {
        h2 {
          font-size: 24px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  .privacy-checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;

    input[type="checkbox"] {
      width: 20px; // Double the size of the checkbox
      height: 20px; // Double the size of the checkbox
      margin-right: 10px;
      margin-top: 4px;
      background-color: var(--dark-text); // Set the color to --dark-text
      border: none; // Remove the border
    }

    label {
      font-size: 14px;
      line-height: 1.4;
      flex: 1;
    }
  }

  .privacy-link {
    color: var(--dark-text);
    font-weight: bold;
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    &:hover::after {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: var(--dark-text);
      transition: width 0.3s ease;
    }

    .iconify {
      margin-left: 4px;
      font-size: 1em;
    }
  }
}
</style>